import { defineStore, storeToRefs } from 'pinia';
import type { ErrorResponse, AppMeta } from '@/types';
import { getErrorResponse } from '@/utils/helpers';
import { getAppMeta, sendDeviceMaid } from '@/api';
import {
	appLogStorage,
	isInAppBrowserStorage,
	showAlerts,
} from '@/utils/local-storage';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import {
	Adjust,
	AdjustConfig,
	AdjustEvent,
} from '@awesome-cordova-plugins/adjust';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { TrackingEvents } from '@/enums';
import { useGtm } from '@gtm-support/vue-gtm';
import { useUserStore } from '@/stores/user';
import type { AdvertisingStatus } from '@capacitor-community/advertising-id/dist/esm/definitions';
import { ref } from 'vue';

interface AppState {
	meta: AppMeta | null;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
	qualificationInProgress: boolean;
	tracking: boolean;
	trackingMaid: string;
	adjustConfig: AdjustConfig | null;
	appLog: string[];
	advertisingStatus: AdvertisingStatus | null;
	nativeUpdate: boolean;
}

export const useAppStore = defineStore('app', {
	state: (): AppState => ({
		meta: null,
		loading: false,
		initLoading: false,
		error: null,
		qualificationInProgress: false,
		tracking: false,
		trackingMaid: '',
		adjustConfig: null,
		appLog: [],
		advertisingStatus: null,
		nativeUpdate: false,
	}),

	getters: {
		appMeta: (state) => state.meta,
		adjustEvents: (state) => state.meta?.adjust_events || null,
		isQualificationInProgress: (state) => state.qualificationInProgress,
		getAdjustConfig: (state) => state.adjustConfig,
		appLogMessages: (state) => state.appLog,
		isNativeAppTrackingEnabled: (state) => state.tracking,
		getTrackingMaid: (state) => state.trackingMaid,
		getAdvertisingStatus: (state) => state.advertisingStatus,
		isNativeUpdateAvailable: (state) => state.nativeUpdate,
	},

	actions: {
		async fetchAppMeta() {
			try {
				this.meta = await getAppMeta();
			} catch (e) {
				this.error = getErrorResponse(e);
			}
		},

		setQualificationInProgress(value: boolean) {
			this.qualificationInProgress = value;
		},

		async initAdjust() {
			const config = new AdjustConfig(
				import.meta.env.ADJUST_TOKEN,
				import.meta.env.ADJUST_TYPE
			);

			Adjust.initSdk(config);
		},

		trackAdjustEvent(eventName: string) {
			const event = new AdjustEvent(eventName);
			const { userId } = storeToRefs(useUserStore());

			if (userId.value) {
				event.addCallbackParameter('user_id', userId.value);
			}

			Adjust.trackEvent(event);
		},

		async setUser(userData: { [key: string]: string }): Promise<void> {
			const { marketingId } = useAppCookies();
			const { isMobileApp } = useMobileApp();

			if (!this.tracking || !marketingId || !isMobileApp) {
				return;
			}
			await FirebaseAnalytics.setUserId({
				userId: marketingId,
			});
			for (const key in userData) {
				await FirebaseAnalytics.setUserProperty({
					key: key,
					value: userData[key],
				});
			}
		},

		async setTracking(maid: string): Promise<void> {
			const { isMobileApp, isInAppBrowser } = useMobileApp();
			this.trackingMaid = maid;
			this.tracking = !!maid;

			this.addLog(`SET TRACKING:::enabled: ${this.tracking}`);
			if (!isMobileApp || isInAppBrowser.value) {
				return;
			}
			await FirebaseAnalytics.setEnabled({
				enabled: this.tracking,
			});

			await this.initAdjust();
		},

		async resetTracking(): Promise<void> {
			const { isMobileApp } = useMobileApp();
			if (!this.tracking || !isMobileApp) {
				return;
			}
			await FirebaseAnalytics.resetAnalyticsData();
		},

		async setCurrentScreen(screenName: string): Promise<void> {
			const { isMobileApp } = useMobileApp();
			if (!this.tracking || !isMobileApp) {
				return;
			}
			await FirebaseAnalytics.setCurrentScreen({
				screenName,
			});
		},

		async trackEvent(
			event: TrackingEvents,
			params: Record<string, string> = {},
			adjustEventName: string | undefined = undefined
		): Promise<void> {
			const { marketingId } = useAppCookies();
			const { isMobileApp, platform, isInAppBrowser } = useMobileApp();

			const trackedWith = ref<string[]>([]);

			this.addLog(
				`TRACK EVENT STARTING:::ENABLED:::${this.tracking}:::EVENT:::${event}:::PARAMS:::${JSON.stringify(params)}:::IN APP BROWSER:::${isInAppBrowserStorage.value}:::IS MOBILE:::${isMobileApp}`
			);

			if (isMobileApp && !this.tracking) {
				this.addLog(`TRACK EVENT ENABLED:::RETURNED:::NOT ENABLED?`);
				this.addLog(`TRACK EVENT ENDING====================`);
				return;
			}
			if (isMobileApp) {
				this.addLog(
					`TRACK EVENT NATIVE BEFORE:::${event}:::PARAMS:::${JSON.stringify(params)}:::PLATFORM:::${platform.value}`
				);

				await FirebaseAnalytics.logEvent({
					name: event,
					params,
				});

				this.addLog(
					`TRACK EVENT:::DONE FB:::${event}:::${JSON.stringify(params)}`
				);

				trackedWith.value.push('FB');

				this.addLog(
					`TRACK EVENT:::BEFORE ADJUST:::${adjustEventName}:::${this.adjustEvents ? this.adjustEvents[event] : undefined}`
				);
				const adjustEvent = adjustEventName
					? adjustEventName
					: this.adjustEvents
						? this.adjustEvents[event]
						: undefined;

				if (adjustEvent) {
					this.trackAdjustEvent(adjustEvent);
					this.addLog(
						`TRACK EVENT:::DONE ADJUST:::${adjustEvent}:::${JSON.stringify(params)}`
					);

					trackedWith.value.push('ADJUST');
				}
			} else {
				this.addLog(
					`TRACK EVENT:::GTM:::${event}:::PLATFORM:::${platform.value}`
				);

				if (isInAppBrowser.value) {
					return;
				}

				const gtm = useGtm();
				gtm?.trackEvent({
					event: event,
					user_id: marketingId || '',
					customer: {
						id: marketingId || '',
					},
					...params,
				});

				trackedWith.value.push('GTM');
			}

			if (showAlerts.value) {
				alert(
					`TRACKED EVENT ::: ${event} WITH:::${trackedWith.value.join(', ')}`
				);
			}

			this.addLog(`TRACK EVENT ENDING====================`);
		},
		addLog(message: string) {
			const { authToken } = useAppCookies();
			if (appLogStorage.value || !authToken) {
				this.appLog.push(message);
			}
		},
		clearLog() {
			this.appLog = [];
		},
		async storeMaid() {
			try {
				const status = await sendDeviceMaid(this.trackingMaid);
				this.addLog(
					`SET TRACKING:::MAID:::${this.trackingMaid}:::SENT status: ${status}`
				);
			} catch (e) {
				this.addLog(
					`SEND MAID ERROR:::MAID:::${this.trackingMaid}:::ERROR:::${e}`
				);
			}
		},
		setAdvertisingStatus(status: AdvertisingStatus) {
			this.advertisingStatus = status;
		},
		setNativeUpdate(value: boolean) {
			this.nativeUpdate = value;
		},
	},
});
